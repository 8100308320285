<template lang="pug">
  .subscribers-filter-form
    el-form(:model="form" ref="form" :rules="rules")
      .form-row
        el-form-item(prop="phone" label="Телефон")
          el-input(v-model="form.phone" maxlength="11" show-word-limit clearable)
        el-form-item(prop="sender_name" label="Отправитель")
          el-autocomplete(v-model="form.sender_name" :fetch-suggestions="fetchSendersSuggestions" @select="senderSuggestionSelected" :trigger-on-focus="false" :debounce="300" clearable)
            template(slot-scope="{ item }")
              div {{ item.name }}
        el-form-item(v-if="form.phone.length && sender_selected")
          el-button(@click="saveSubscription" type="primary") Добавить отписку
</template>

<script>
import {mapActions} from 'vuex';

export default {
  props: [
    'form'
  ],
  data() {
    return {
      rules: {
        phone: [
          { required: true, message: 'Укажите номер телефона', trigger: 'blur' },
          { min: 11, max: 11, message: 'Номер телефона должен состоять из 11 цифр', trigger: 'blur' },

        ],
        sender_name: [
          { required: true, message: 'Выберите отправителя', trigger: 'blur' },
        ],
      },
      sender_selected: false,
    }
  },
  created() {
    this.debouncedUpdate = _.debounce(()=>this.$emit('updated'), 500);

    this.$emit('created');

  },
  watch: {
    form: {
      handler() {
        this.debouncedUpdate();
      },
      deep: true,
    }
  },
  methods: {
    ...mapActions([
      'GET_SENDERS_SUGGESTIONS',
      'MANAGE_SAVE_SUBSCRIPTION',
    ]),
    fetchSendersSuggestions(q, cb){
      this.GET_SENDERS_SUGGESTIONS({name: q}).then((senders)=>{
        cb(senders);
      });
    },
    senderSuggestionSelected(sender){
      this.form.sender_name = sender.name;
      this.sender_selected = true;
    },
    saveSubscription() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.MANAGE_SAVE_SUBSCRIPTION(this.form).then(()=>{
            this.form.sender_name = '';
          });
        }else{
          return false;
        }
      });
    },
    resetForm() {
      this.$refs['form'].resetFields();
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
