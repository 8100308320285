<i18n src="@/i18n/subscriber.json"></i18n>

<template lang="pug">
  .manage-subscribers
    section
      .section-content-wrapper
        .grid
          nav
            router-link(:to="{name: 'manage.subscribers'}") Абоненты
          div
            h1 Абоненты
            SubscribersFilterForm(:form="subscribers_filter.form" @created="fetchSubscribers(true)" @updated="fetchSubscribers(true)")
            .subscribers(v-if="subscribers.total")
              .subscriber-title
                div Телефон
                div
                div Дата регистрации
                div Дата верификации
              .subscriber-card(v-for="subscriber in subscribers.data" :key="subscriber.id")
                .always(@click="toggleSubscriberDetails(subscriber.id)")
                  div
                    b {{ subscriber.phone }}
                  div {{ subscriber.subscriptions.length ? $tc('subscriber.subscription.label', subscriber.subscriptions.length) : '' }}
                  div {{ $moment.utc(subscriber.created_at).local().format('lll') }}
                  div {{ subscriber.verified_at ? $moment.utc(subscriber.verified_at).local().format('llll') : '' }}
                .sometimes(v-if="active_subscribers.indexOf(subscriber.id) >= 0")
                  div(v-if="subscriber.subscriptions.length")
                    .subscription(v-for="subscription of subscriber.subscriptions")
                      div <b>{{ subscription.sender_name }}</b> {{ $moment.utc(subscription.updated_at).local().format('llll') }}
                      el-switch(v-model="subscription.unsubscribed" @change="saveSubscription(subscription)" :inactive-text="subscription.unsubscribed ? 'Отписан' : 'Подписан'" :class="subscription.unsubscribed ? 'active' : subscription.unsubscribed === false ? 'inactive' : ''")
              .pagintion(v-if="subscribers.total > subscribers.to")
                el-button(@click="fetchMoreSubscribers" type="primary") Показать ещё
</template>

<script>
import {mapActions} from 'vuex';

import SubscribersFilterForm from "@/components/Manage/Subscribers/SubscribersFilterForm";

const default_subscribers_filter = () => {
  return {
    meta: {
      page: 1
    },
    form: {
      channel_alias: 'sms',
      phone: '',
      sender_name: '',
    },
  }
}

export default {
  components: {
    SubscribersFilterForm,
  },
  data() {
    return {
      subscribers_filter: default_subscribers_filter(),
      sender_name: '',
      subscribers: {},
      active_subscribers: [],
    }
  },
  methods: {
    ...mapActions([
      'MANAGE_GET_SUBSCRIBERS',
      'MANAGE_SAVE_SUBSCRIPTION',
    ]),
    fetchSubscribers(with_reset = false) {
      if (with_reset){
        this.active_subscribers = [];
        this.subscribers_filter.meta.page = 1;
      }

      this.MANAGE_GET_SUBSCRIBERS({...this.subscribers_filter.form, ...{page: this.subscribers_filter.meta.page}}).then((subscribers) => {
        if(with_reset){
          this.subscribers = subscribers;
        }else{
          this.subscribers.data = this.subscribers.data.concat(subscribers.data);
        }

        if(this.subscribers.total === 1) this.toggleSubscriberDetails(this.subscribers.data[0]['id']);
      }).catch((error) => {
        if(error.response && error.response.data.errors){
          this.$root.pushAppMessages(error.response.data.errors.map((message)=>{
            return this.$t(message)
          }))
        }
      })
    },
    fetchMoreSubscribers() {
      this.subscribers_filter.meta.page++;

      this.fetchSubscribers();
    },
    toggleSubscriberDetails(id){
      let index = this.active_subscribers.indexOf(id);
      if(index >= 0){
        this.active_subscribers.splice(index, 1);
      }else{
        this.active_subscribers.push(id);
      }
    },
    saveSubscription(subscription){
      this.MANAGE_SAVE_SUBSCRIPTION(subscription).catch((error) => {
        if(error.response && error.response.data.errors){
          this.$root.pushAppMessages(error.response.data.errors.map((message)=>{
            return this.$t(message)
          }))
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.manage-subscribers {
  section {
    background: $color--background;
    color: $color--black;
  }
  nav {
    display: none;
  }

  .subscription {
    &-create {
      background: $color--secondary;
      color: $color--black;
    }
    &-edit {
      &-form {
        width: 50%;
      }
    }
  }
  .grid {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    grid-gap: $padding;
  }
  .pagintion {
    margin-top: $padding12;
  }
  .subscriber {
    &-title, &-card .always {
      display: flex;

      > * {
        padding: $padding18 $padding14;
        width: 25%;
      }
    }
    &-title {
      color: $color--secondary-dark;
    }
    &-card {
      @include border(1px dotted $color--secondary-dark, $position: bottom);

      .always{
        cursor: pointer;
      }
      .sometimes {
        padding: $padding18 $padding14;

        .subscription{
          @include border(1px dotted $color--secondary-dark, $position: top);

          display: flex;
          justify-content: space-between;
          padding: $padding18 $padding12;
        }
      }

      &:nth-child(even) {
        .always {
          //background: $color--secondary;
        }
      }
    }
  }
}
</style>
