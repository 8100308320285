import { render, staticRenderFns } from "./ManageSubscribers.vue?vue&type=template&id=2cef1d0d&scoped=true&lang=pug&"
import script from "./ManageSubscribers.vue?vue&type=script&lang=js&"
export * from "./ManageSubscribers.vue?vue&type=script&lang=js&"
import style0 from "./ManageSubscribers.vue?vue&type=style&index=0&id=2cef1d0d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cef1d0d",
  null
  
)

/* custom blocks */
import block0 from "@/i18n/subscriber.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fvar%2Fwww%2Fopt-in.ru%2Fsrc%2Fviews%2Fmanage%2FManageSubscribers.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports